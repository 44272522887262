import React from 'react';
import { VStack, HStack, Select, Button, Input, Textarea, Divider } from '@chakra-ui/react';
import {useQuestContext} from './context/QuestContext';
import TaskForm from './components/TaskForm';
import TaskDisplay from './components/TaskDisplay';

const Quests: React.FC = () => {
  const {
    questChanged,
    quests,
    questIdToEdit,
    questNameToEdit,
    questDescriptionToEdit,
    questContextPromptToEdit,
    questFinishPromptToEdit,
    taskIdToEdit,
    questTasksToEdit,
    setQuestNameToEdit,
    setQuestDescriptionToEdit,
    setQuestContextPromptToEdit,
    setQuestFinishPromptToEdit,
    handlePublishQuest,
    handleDeleteQuest,
    handleSelectQuest,
    handleAddNewQuest,
  } = useQuestContext();

  return (
    <VStack spacing={4} w="full" p="4">
      <HStack w="100%">
        <Select
          placeholder="New Quest"
          value={questIdToEdit}
          onChange={(e) => handleSelectQuest(e.target.value === '' ? undefined : e.target.value)}
        >
          {quests.map(quest => (
            <option key={quest.id} value={quest.id}>{quest.name}</option>
          ))}
        </Select>
      </HStack>
      <VStack w="full">
        <Input placeholder="Quest Name" value={questNameToEdit} onChange={(e) => setQuestNameToEdit(e.target.value)} />
        <Textarea w="full" placeholder="Quest Description" value={questDescriptionToEdit} onChange={(e) => setQuestDescriptionToEdit(e.target.value)} />
        <HStack w="full">
          <Textarea w="full" placeholder="Context Prompt" value={questContextPromptToEdit} onChange={(e) => setQuestContextPromptToEdit(e.target.value)} />
          <Textarea w="full" placeholder="Finish Prompt" value={questFinishPromptToEdit} onChange={(e) => setQuestFinishPromptToEdit(e.target.value)} />
        </HStack>
        <Divider w="full" />
        {questTasksToEdit.map(task => (
          task.id === taskIdToEdit ? <TaskForm key={task.id} /> : <TaskDisplay key={task.id} task={task} />
        ))}
        {!taskIdToEdit && <TaskForm />}
        <Divider w="full" />
        <HStack ml="auto">
          {!!questIdToEdit && (
            <Button ml="auto" onClick={() => handleDeleteQuest(questIdToEdit)}>Delete Quest</Button>
          )}
          <Button colorScheme={questChanged ? 'orange' : 'gray'} onClick={handlePublishQuest}>Publish Quest</Button>
          {!!questIdToEdit && <Button onClick={handleAddNewQuest}>Add New Quest</Button>}
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Quests;