// src/components/TaskForm.tsx
import React from 'react';
import {VStack, HStack, Input, Textarea, Button, Text, Checkbox} from '@chakra-ui/react';
import {useQuestContext} from '../context/QuestContext';

const TaskForm: React.FC = () => {
  const {
    taskNameToEdit,
    setTaskNameToEdit,
    taskPromptToEdit,
    setTaskPromptToEdit,
    taskLatitudeToEdit,
    setTaskLatitudeToEdit,
    taskLongitudeToEdit,
    setTaskLongitudeToEdit,
    taskRadiusKmToEdit,
    setTaskRadiusKmToEdit,
    taskIdToEdit,
    setTaskImageToEdit,
    taskImageToEdit,
    setTaskUseTeleportToEdit,
    taskUseTeleportToEdit,
    setTaskTeleportLongitudeToEdit,
    taskTeleportLongitudeToEdit,
    setTaskTeleportLatitudeToEdit,
    taskTeleportLatitudeToEdit,
    setTaskTeleportAltitudeToEdit,
    taskTeleportAltitudeToEdit,
    setTaskTeleportHeadingToEdit,
    taskTeleportHeadingToEdit,
    setTaskTeleportSpeedToEdit,
    taskTeleportSpeedToEdit,
    handleAddTask,
    handleSaveTask,
    handleCancelEditing
  } = useQuestContext();

  return (
    <VStack w="full" p={4} borderWidth={1} borderRadius="md" spacing={2} bg="lightskyblue">
      <Input bg="white" w="full" placeholder="Task Name" value={taskNameToEdit}
             onChange={(e) => setTaskNameToEdit(e.target.value)}/>
      <Textarea bg="white" placeholder="Task Prompt" value={taskPromptToEdit}
                onChange={(e) => setTaskPromptToEdit(e.target.value)}/>
      <HStack w="full">
        <HStack w={taskUseTeleportToEdit ? "40%" : "full"}>
          <Text>TARGET:</Text>
          <Input bg="white" placeholder="Longitude" type="number"
                 value={isNaN(taskLongitudeToEdit) ? '' : taskLongitudeToEdit}
                 onChange={(e) => setTaskLongitudeToEdit(parseFloat(e.target.value))}/>
          <Input bg="white" placeholder="Latitude" type="number"
                 value={isNaN(taskLatitudeToEdit) ? '' : taskLatitudeToEdit}
                 onChange={(e) => setTaskLatitudeToEdit(parseFloat(e.target.value))}/>
          <Input w="300px" bg="white" placeholder="Radius (km)" type="number"
                 value={isNaN(taskRadiusKmToEdit) ? '' : taskRadiusKmToEdit}
                 onChange={(e) => setTaskRadiusKmToEdit(parseFloat(e.target.value))}/>
        </HStack>
        <HStack w={taskUseTeleportToEdit ? "60%" : "unset"}>
          <Text>TELEPORT:</Text>
          <Checkbox
            isChecked={taskUseTeleportToEdit}
            onChange={(e) => setTaskUseTeleportToEdit(e.target.checked)}
          />
          {taskUseTeleportToEdit && (
            <HStack>
              <Input bg="white" placeholder="Longitude" type="number"
                     value={isNaN(taskTeleportLongitudeToEdit) ? '' : taskTeleportLongitudeToEdit}
                     onChange={(e) => setTaskTeleportLongitudeToEdit(parseFloat(e.target.value))}/>
              <Input bg="white" placeholder="Latitude" type="number"
                      value={isNaN(taskTeleportLatitudeToEdit) ? '' : taskTeleportLatitudeToEdit}
                      onChange={(e) => setTaskTeleportLatitudeToEdit(parseFloat(e.target.value))}/>
              <Input bg="white" placeholder="Altitude (feet)" type="number"
                      value={isNaN(taskTeleportAltitudeToEdit) ? '' : taskTeleportAltitudeToEdit}
                      onChange={(e) => setTaskTeleportAltitudeToEdit(parseFloat(e.target.value))}/>
              <Input bg="white" placeholder="Heading (true)" type="number"
                      value={isNaN(taskTeleportHeadingToEdit) ? '' : taskTeleportHeadingToEdit}
                      onChange={(e) => setTaskTeleportHeadingToEdit(parseFloat(e.target.value))}/>
              <Input bg="white" placeholder="Speed (knots)" type="number"
                      value={isNaN(taskTeleportSpeedToEdit) ? '' : taskTeleportSpeedToEdit}
                      onChange={(e) => setTaskTeleportSpeedToEdit(parseFloat(e.target.value))}/>
            </HStack>
          )}
        </HStack>
      </HStack>
      <HStack w="full">
        <Input bg="white" w="full" placeholder="Image URL" value={taskImageToEdit}
               onChange={(e) => setTaskImageToEdit(e.target.value)}/>
        {taskIdToEdit && <Button onClick={handleCancelEditing}>Cancel</Button>}
        {taskIdToEdit ? <Button onClick={handleSaveTask}>Save Task</Button> :
          <Button onClick={handleAddTask}>Add Task</Button>}
      </HStack>
    </VStack>
  );
};

export default TaskForm;