// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Stats from './Stats';
import { Landing } from './landing/Landing';
import Quests from './quests/Quests';
import { QuestProvider } from './quests/context/QuestContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={createBrowserRouter([
        {
          path: '/',
          element: <Landing />,
        },
        {
          path: '/stats',
          element: <Stats />,
        },
        {
          path: '/quests',
          element: (
            <QuestProvider>
              <Quests />
            </QuestProvider>
          ),
        }
      ])} />
    </ChakraProvider>
  </React.StrictMode>
);

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();