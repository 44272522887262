import React from "react";

import {Header} from "./Header";
import {Showcases} from "./Showcases";
import {VStack} from "@chakra-ui/react";
import { Testimonials } from "./Testimonials";
import {Pricing} from "./Pricing";
import {Footer} from "./Footer";

export const Landing = () => {
  return (
    <VStack w="full" h="full" gap={0}>
      <Header />
      <Showcases />
      <Testimonials />
      <Pricing />
      <Footer />
    </VStack>
  )
}