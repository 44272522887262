import {Box, Grid, Text, VStack} from "@chakra-ui/react";
import {DownloadButton} from "./DownloadButton";

type Testimonial = {
  name: string
  text: string
}

const testimonials: Testimonial[] = [
  {
    name: "Launchpad McQuack",
    text: "AviMate is like having my own co-pilot, without the crash landings! It's made my flights smoother and way more fun. Never thought I'd say this, but I might not even need a crash helmet anymore!"
  },
  {
    name: "Baloo von Bruinwald XIII",
    text: "I've been flying for years, but AviMate has made it feel like I'm flying for the first time all over again. It's like having a best friend in the cockpit with me!"
  },
  {
    name: "Han Solo",
    text: "I've flown a lot of ships in my time, but AviMate is the best co-pilot I've ever had. It's like having a second pair of eyes and hands, but without the backseat driving!"
  },
  {
    name: "Maverick",
    text: "I've been flying solo for years, but AviMate has made me feel like I'm part of a team again. It's like having a wingman who's always got my back!"
  },
  {
    name: "Poe Dameron",
    text: "With AviMate, every flight feels like I'm leading an X-wing squadron. It’s intuitive and packed with info, making it the perfect co-pilot for any mission."
  },
  {
    name: "SkyX",
    text: "AviMate is definitely a must have add-on to be submerged in real-life like pilot conditions. We recommend ⭐"
  }
]

export const Testimonials = () => {
  return (
    <Box
      bgGradient="linear(to-b, #eaf8fd, #d4f4ff)"
      w="full"
    >
      <VStack maxW="940px" ml="auto" mr="auto" pt="50px" px={{base: 5, lg: 0}}>
        <Grid w="full" templateColumns={{base: 'repeat(1, 1fr)',sm: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} gap={8} mt={10} autoRows="1fr">
          {testimonials.map((testimonial: Testimonial, index: number) => (
            <VStack
              key={index}
              borderColor="#f1d76e"
              borderRadius="30px"
              borderWidth="10px"
              backgroundColor="white"
              p={3}
              position="relative"
              maxW={{base: "100%", md: "400px"}}
            >
              <Text
                mt={1}
                fontSize="md"
                fontFamily="Exo-Bold"
                color="black"
              >
                {testimonial.name}
              </Text>
              <Text fontSize="sm">
                {testimonial.text}
              </Text>
              <Box
                position="absolute"
                right="-30px"
                top="-30px"
                w="60px"
                h="60px"
                borderRadius="30px"
                borderWidth="10px"
                backgroundColor="#f1d76e"
                borderColor="white"
                color="white"
                fontSize="6xl"
              >
                <Text position="absolute" top="-14px" left="5px">❞</Text>
              </Box>
            </VStack>
          ))}
        </Grid>
        <DownloadButton/>
      </VStack>
    </Box>
  )
}