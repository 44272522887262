import widgetBmp from "./images/widget.png";
import React, {useEffect} from "react";
import {Box, HStack, Image, Text, VStack} from "@chakra-ui/react";
import skyBackgroundBmp from "../landing/images/sky3-p-1600.png";
import aviLogoBmp from "../landing/images/logo512-p-500.png";
import planeVintageBmp from "../landing/images/plane_vintage.png";
export const TEXT_SHADOW_SMALL = "1px 2px 0px #000000"

export const Header = () => {
  useEffect(() => {
    import("../../package.json").then((pkg) => {
      console.log(`Version: ${pkg.version}`);
    });
  }, []);

  return (
    <Box
      backgroundImage={skyBackgroundBmp}
      backgroundSize="cover"
      backgroundPosition="center"
      color="white"
      fontWeight="bold"
      fontFamily="Nourd-Bold"
      py="100px"
      w="full"
    >
      <VStack alignItems="start" maxW="940px" mx={{base: 5, lg: "auto"}}>
        <HStack gap="0" w="full">
          <Image w="90px" src={aviLogoBmp}/>
          <Text
            fontSize={{base: "5xl", sm: "6xl"}}
            textShadow="2px 3px 0px #000000"
          >
            AviMate
          </Text>
        </HStack>
        <Text
          fontSize="2xl"
          textShadow={TEXT_SHADOW_SMALL}
        >
          Push-To-Talk AI Co-Pilot for MSFS and X-Plane!
        </Text>
        <HStack w="100%" gap="30px" mt="40px" justifyContent="center" ml={{base: 0, sm: "5%", md: 0}}>
          <Image src={planeVintageBmp} display={{base: "none", md: "block"}} maxW={{md: "40%", lg: "50%"}}
                 flexShrink="1"/>
          <VStack maxW="100%" position="relative" flexShrink="0" alignItems="start" p="10px">
            <Image src={widgetBmp} mt="20px" w="auto"/>
            <HStack gap="20px" mt="20px" w="100%">
              <Box
                ml="20px"
                w="30px"
                h="140px"
                borderColor="white"
                borderStyle="dashed"
                borderWidth="2px"
                borderTopLeftRadius="5px"
                borderBottomLeftRadius="5px"
                borderRight="none"
              />
              <VStack
                alignItems="start"
              >
                <Text
                  fontSize="xl"
                  textShadow={TEXT_SHADOW_SMALL}
                >
                  Adventure Flights & Quests
                </Text>
                <Text
                  fontSize="xl"
                  textShadow={TEXT_SHADOW_SMALL}
                >
                  Aviation Trivia
                </Text>
                <Text
                  fontSize="xl"
                  textShadow={TEXT_SHADOW_SMALL}
                >
                  Voice Commands
                </Text>
                <Text
                  fontSize="xl"
                  textShadow={TEXT_SHADOW_SMALL}
                >
                  Navigation Assistance
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  )
}