import React, { createContext, useContext, useState, useCallback, useRef, useEffect, ReactNode } from 'react';
import { Quest, TaskOrder } from '../types';
import {
  getQuestsFromStorage,
  addQuestToStorage,
  updateQuestInStorage,
  deleteQuestFromStorage
} from '../utils/firebaseStorage';

interface QuestContextProps {
  quests: Quest[];
  taskIdToEdit: string | undefined;
  questNameToEdit: string;
  questDescriptionToEdit: string;
  questContextPromptToEdit: string;
  questFinishPromptToEdit: string;
  questTasksToEdit: TaskOrder[];
  taskNameToEdit: string;
  taskPromptToEdit: string;
  taskLatitudeToEdit: number;
  taskLongitudeToEdit: number;
  taskRadiusKmToEdit: number;
  taskImageToEdit: string;
  questIdToEdit: string | undefined;
  setQuestNameToEdit: (name: string) => void;
  setQuestDescriptionToEdit: (description: string) => void;
  setQuestContextPromptToEdit: (prompt: string) => void;
  setQuestFinishPromptToEdit: (prompt: string) => void;
  setTaskNameToEdit: (name: string) => void;
  setTaskPromptToEdit: (prompt: string) => void;
  setTaskLatitudeToEdit: (latitude: number) => void;
  setTaskLongitudeToEdit: (longitude: number) => void;
  setTaskRadiusKmToEdit: (radius: number) => void;
  setTaskImageToEdit: (image: string) => void;
  handlePublishQuest: () => void;
  handleDeleteQuest: (id: string) => void;
  handleSelectQuest: (id: string | undefined) => void;
  handleAddTask: () => void;
  handleDeleteTask: (taskId: string) => void;
  handleEditTask: (task: TaskOrder) => void;
  handleSaveTask: () => void;
  handleCancelEditing: () => void;
  handleAddNewQuest: () => void;
  questChanged: boolean;
  taskUseTeleportToEdit: boolean;
  setTaskUseTeleportToEdit: (useTeleport: boolean) => void;
  taskTeleportLongitudeToEdit: number;
  setTaskTeleportLongitudeToEdit: (longitude: number) => void;
  taskTeleportLatitudeToEdit: number;
  setTaskTeleportLatitudeToEdit: (latitude: number) => void;
  taskTeleportAltitudeToEdit: number;
  setTaskTeleportAltitudeToEdit: (altitude: number) => void;
  taskTeleportHeadingToEdit: number;
  setTaskTeleportHeadingToEdit: (heading: number) => void;
  taskTeleportSpeedToEdit: number;
  setTaskTeleportSpeedToEdit: (speed: number) => void;
}

interface QuestProviderProps {
  children: ReactNode;
}

const QuestContext = createContext<QuestContextProps | undefined>(undefined);

export const QuestProvider: React.FC<QuestProviderProps> = ({ children }) => {
  const taskIdCounterRef = useRef<number>(0);
  const questIdCounterRef = useRef<number>(0);
  const isInitialized = useRef<boolean>(false);

  const constructEmptyQuest = () => ({ id: `${questIdCounterRef.current++}`, tasks: [] } as Partial<Quest>);
  const constructEmptyTask = () => ({ id: `${taskIdCounterRef.current++}` } as Partial<TaskOrder>);

  const [questChanged, setQuestChanged] = useState<boolean>(false);
  const [quests, setQuests] = useState<Quest[]>([]);
  const [questIdToEdit, setQuestIdToEdit] = useState<string | undefined>(undefined);
  const [taskIdToEdit, setTaskIdToEdit] = useState<string | undefined>(undefined);
  const [questDescriptionToEdit, setQuestDescriptionToEdit] = useState<string>('');
  const [questNameToEdit, setQuestNameToEdit] = useState<string>('');
  const [questContextPromptToEdit, setQuestContextPromptToEdit] = useState<string>('');
  const [questFinishPromptToEdit, setQuestFinishPromptToEdit] = useState<string>('');
  const [questTasksToEdit, setQuestTasksToEdit] = useState<TaskOrder[]>([]);
  const [taskNameToEdit, setTaskNameToEdit] = useState<string>('');
  const [taskPromptToEdit, setTaskPromptToEdit] = useState<string>('');
  const [taskLatitudeToEdit, setTaskLatitudeToEdit] = useState<number>(NaN);
  const [taskLongitudeToEdit, setTaskLongitudeToEdit] = useState<number>(NaN);
  const [taskRadiusKmToEdit, setTaskRadiusKmToEdit] = useState<number>(NaN);
  const [taskImageToEdit, setTaskImageToEdit] = useState<string>('');
  const [questsLoaded, setQuestsLoaded] = useState<boolean>(false);
  const [taskUseTeleportToEdit, setTaskUseTeleportToEdit] = useState<boolean>(false);
  const [taskTeleportLongitudeToEdit, setTaskTeleportLongitudeToEdit] = useState<number>(NaN);
  const [taskTeleportLatitudeToEdit, setTaskTeleportLatitudeToEdit] = useState<number>(NaN);
  const [taskTeleportAltitudeToEdit, setTaskTeleportAltitudeToEdit] = useState<number>(NaN);
  const [taskTeleportHeadingToEdit, setTaskTeleportHeadingToEdit] = useState<number>(NaN);
  const [taskTeleportSpeedToEdit, setTaskTeleportSpeedToEdit] = useState<number>(NaN);

  useEffect(() => {
    if (!isInitialized.current) {
      void fetchQuests();
      isInitialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (questsLoaded) {
      const maxQuestId = Math.max(...quests.map(quest => parseInt(quest.id)), 0);
      questIdCounterRef.current = maxQuestId + 1;
    }
  }, [quests, questsLoaded]);

  useEffect(() => {
    if(!!questIdToEdit) {
      const questToEdit = quests.find(quest => quest.id === questIdToEdit);
      if(!questToEdit) throw new Error(`Quest with id ${questIdToEdit} not found`);
      setQuestNameToEdit(questToEdit.name);
      setQuestDescriptionToEdit(questToEdit.description);
      setQuestContextPromptToEdit(questToEdit.contextPrompt);
      setQuestFinishPromptToEdit(questToEdit.finishPrompt);
      setQuestTasksToEdit(questToEdit.tasks);
    } else {
      setQuestNameToEdit('');
      setQuestDescriptionToEdit('');
      setQuestContextPromptToEdit('');
      setQuestFinishPromptToEdit('');
      setQuestTasksToEdit([]);
      resetTaskEdits();
    }
  }, [questIdToEdit, quests]);

  useEffect(() => {
    if (questsLoaded) {
      taskIdCounterRef.current = Math.max(...questTasksToEdit.map(task => parseInt(task.id)), 0) + 1;
    }
  }, [questTasksToEdit, questsLoaded]);

  const fetchQuests = async () => {
    const quests = await getQuestsFromStorage();
    setQuests(quests);
    setQuestsLoaded(true);
  }

  const handlePublishQuest = () => {
    const questToEdit = quests.find(quest => quest.id === questIdToEdit) ?? constructEmptyQuest();
    questToEdit.name = questNameToEdit;
    questToEdit.description = questDescriptionToEdit;
    questToEdit.contextPrompt = questContextPromptToEdit;
    questToEdit.finishPrompt = questFinishPromptToEdit;
    questToEdit.tasks = questTasksToEdit;
    if (questIdToEdit === undefined) {
      void addQuestToStorage(questToEdit as Quest).then(
        async () => {
          await fetchQuests();
          setQuestIdToEdit(questToEdit.id);
        }
      );
    } else {
      void updateQuestInStorage(questToEdit as Quest).then(
        () => fetchQuests()
      );
    }
    setQuestChanged(false);
  };

  const handleDeleteQuest = async (id: string) => {
    await deleteQuestFromStorage(id);
    await fetchQuests();
    setQuestIdToEdit(undefined);
    setQuestChanged(false);
  };

  const handleAddNewQuest = () => {
    setQuestIdToEdit(undefined);
  }

  const handleSelectQuest = (id: string | undefined) => {
    setQuestIdToEdit(id);
  };

  const syncTaskWithEdits = useCallback((taskToEdit: Partial<TaskOrder>) => {
    if (!taskToEdit) return;
    if (!taskNameToEdit || !taskPromptToEdit || !taskLatitudeToEdit || !taskLongitudeToEdit || !taskRadiusKmToEdit) return;
    taskToEdit.name = taskNameToEdit;
    taskToEdit.prompt = taskPromptToEdit;
    taskToEdit.latitude = taskLatitudeToEdit;
    taskToEdit.longitude = taskLongitudeToEdit;
    taskToEdit.radiusKm = taskRadiusKmToEdit;
    taskToEdit.image = taskImageToEdit;
    taskToEdit.useTeleport = taskUseTeleportToEdit;
    taskToEdit.teleportLatitude = taskTeleportLatitudeToEdit;
    taskToEdit.teleportLongitude = taskTeleportLongitudeToEdit;
    taskToEdit.teleportAltitude = taskTeleportAltitudeToEdit;
    taskToEdit.teleportHeading = taskTeleportHeadingToEdit;
    taskToEdit.teleportSpeed = taskTeleportSpeedToEdit;
  }, [
    taskNameToEdit,
    taskPromptToEdit,
    taskLatitudeToEdit,
    taskLongitudeToEdit,
    taskRadiusKmToEdit,
    taskImageToEdit,
    taskUseTeleportToEdit,
    taskTeleportLatitudeToEdit,
    taskTeleportLongitudeToEdit,
    taskTeleportAltitudeToEdit,
    taskTeleportHeadingToEdit,
    taskTeleportSpeedToEdit
  ]);

  const resetTaskEdits = () => {
    setTaskIdToEdit(undefined);
    setTaskNameToEdit('');
    setTaskPromptToEdit('');
    setTaskLatitudeToEdit(NaN);
    setTaskLongitudeToEdit(NaN);
    setTaskRadiusKmToEdit(NaN);
    setTaskImageToEdit('');
    setTaskUseTeleportToEdit(false);
    setTaskTeleportLatitudeToEdit(NaN);
    setTaskTeleportLongitudeToEdit(NaN);
    setTaskTeleportAltitudeToEdit(NaN);
    setTaskTeleportHeadingToEdit(NaN);
    setTaskTeleportSpeedToEdit(NaN);
  }

  const handleAddTask = useCallback(() => {
    const taskToSave = constructEmptyTask() as TaskOrder;
    syncTaskWithEdits(taskToSave);
    setQuestTasksToEdit([...questTasksToEdit, taskToSave]);
    resetTaskEdits();
    setQuestChanged(true);
  }, [questTasksToEdit, syncTaskWithEdits]);

  const handleSaveTask = useCallback(() => {
    const taskToEdit = questTasksToEdit.find(task => task.id === taskIdToEdit);
    if (!taskToEdit) return;
    syncTaskWithEdits(taskToEdit);
    setQuestTasksToEdit([...questTasksToEdit]);
    resetTaskEdits();
    setQuestChanged(true);
  }, [questTasksToEdit, taskIdToEdit, syncTaskWithEdits]);

  const handleCancelEditing = useCallback(() => {
    resetTaskEdits();
  }, []);

  const handleDeleteTask = useCallback((taskId: string) => {
    setQuestTasksToEdit(questTasksToEdit.filter(task => task.id !== taskId));
    setQuestChanged(true);
  }, [questTasksToEdit]);

  const handleEditTask = (task: TaskOrder) => {
    setTaskIdToEdit(task.id);
    setTaskNameToEdit(task.name);
    setTaskLongitudeToEdit(task.longitude);
    setTaskLatitudeToEdit(task.latitude);
    setTaskRadiusKmToEdit(task.radiusKm);
    setTaskPromptToEdit(task.prompt);
    setTaskImageToEdit(task.image);
    setTaskUseTeleportToEdit(task.useTeleport);
    setTaskTeleportLatitudeToEdit(task.teleportLatitude);
    setTaskTeleportLongitudeToEdit(task.teleportLongitude);
    setTaskTeleportAltitudeToEdit(task.teleportAltitude);
    setTaskTeleportHeadingToEdit(task.teleportHeading);
    setTaskTeleportSpeedToEdit(task.teleportSpeed);
  };

  return (
    <QuestContext.Provider value={{
      quests,
      questIdToEdit,
      taskIdToEdit,
      questNameToEdit,
      questDescriptionToEdit,
      questContextPromptToEdit,
      questFinishPromptToEdit,
      taskNameToEdit,
      taskPromptToEdit,
      taskLatitudeToEdit,
      taskImageToEdit,
      taskLongitudeToEdit,
      taskRadiusKmToEdit,
      taskUseTeleportToEdit,
      taskTeleportLatitudeToEdit,
      taskTeleportLongitudeToEdit,
      taskTeleportAltitudeToEdit,
      taskTeleportHeadingToEdit,
      taskTeleportSpeedToEdit,
      // we need to set the questChanged state to true whenever we change the quest name, context prompt, or finish prompt,
      // but for tasks we only need to set it to true when we add or delete a task, editing task fields doesn't go to quest immediately.
      setQuestNameToEdit: (name: string) => {
        setQuestNameToEdit(name);
        setQuestChanged(true);
      },
      setQuestDescriptionToEdit: (description: string) => {
        setQuestDescriptionToEdit(description);
        setQuestChanged(true);
      },
      setQuestContextPromptToEdit: (prompt: string) => {
        setQuestContextPromptToEdit(prompt);
        setQuestChanged(true);
      },
      setQuestFinishPromptToEdit: (prompt: string) => {
        setQuestFinishPromptToEdit(prompt);
        setQuestChanged(true);
      },
      setTaskTeleportLatitudeToEdit,
      setTaskTeleportLongitudeToEdit,
      setTaskTeleportAltitudeToEdit,
      setTaskTeleportHeadingToEdit,
      setTaskTeleportSpeedToEdit,
      setTaskUseTeleportToEdit,
      setTaskNameToEdit,
      setTaskPromptToEdit,
      setTaskLatitudeToEdit,
      setTaskLongitudeToEdit,
      setTaskRadiusKmToEdit,
      setTaskImageToEdit,
      handlePublishQuest,
      handleDeleteQuest,
      handleSelectQuest,
      handleAddTask,
      questTasksToEdit,
      handleDeleteTask,
      handleEditTask,
      handleSaveTask,
      handleCancelEditing,
      handleAddNewQuest,
      questChanged,
    }}>
      {children}
    </QuestContext.Provider>
  );
};

export const useQuestContext = () => {
  const context = useContext(QuestContext);
  if (!context) {
    throw new Error('useQuestContext must be used within a QuestProvider');
  }
  return context;
};