import {Button, VStack} from "@chakra-ui/react";

export function DownloadButton() {
  return <Button
    bgColor="#ff7e33"
    _hover={{bgColor: "#dd6e33"}}
    padding={6}
    color="white"
    fontSize="2xl"
    height="80px"
    lineHeight="20px"
    fontFamily="Exo-Light"
    mt={10}
    onClick={() => window.open("https://firebasestorage.googleapis.com/v0/b/avimate-8048c.appspot.com/o/releases%2FAviMate.exe?alt=media&token=7df8274d-275e-4199-bb82-55160406dc6b")}
  >
    <VStack>
      <div>Download AviMate</div>
      <div>For Windows</div>
    </VStack>
  </Button>;
}