import {Box, HStack, VStack, Text, Image} from "@chakra-ui/react";
import {DownloadButton} from "./DownloadButton";
import airplane from "./images/airplane_1-p-500.png";
import React from "react";

type PricingProps = {
  price: string;
  label: string;
  words: string;
  period: string;
  features: string[];
  color: string;
  shadedColor: string;
}

const pricing: PricingProps[] = [
  {
    price: "FREE",
    label: "DEMO",
    words: "500",
    period: "WORDS",
    features: [
      "~1 HOUR",
      "ALL FEATURES",
    ],
    color: "#f7d55b",
    shadedColor: "#a58e3a"
  },
  {
    price: "$10",
    label: "STANDARD",
    words: "10.000",
    period: "WORDS / MONTH",
    features: [
      "~5 HOURS",
      "ALL FEATURES",
    ],
    color: "#58b3c5",
    shadedColor: "#507d8a"
  },
  {
    price: "$20",
    label: "PREMIUM",
    words: "20.000",
    period: "WORDS / MONTH",
    features: [
      "~25 HOURS",
      "ALL FEATURES",
      "+BETA FEATURES"
    ],
    color: "#ff7e33",
    shadedColor: "#a75927"
  }
]

const mainBlockWidth = 250;
const wrapperPaddingX = 15;
const wrapperPaddingTop = 134;
const wrapperHeight = 140;


export const Pricing = () => {
  return (
    <Box bgColor="#d4f4ff" w="full">
      <VStack
        mt="50px"
      >
        <HStack
          maxW="940px"
          ml="auto"
          mr="auto"
          alignItems="start"
          justifyContent="center"
          flexWrap="wrap"
          rowGap={6}
        >
          {pricing.map((price, index) => (
            <Box w={mainBlockWidth + wrapperPaddingX * 2} position="relative" key={index}>
              <Box
                position="absolute"
                top={wrapperPaddingTop - wrapperPaddingX}
                borderRadius={10}
                bgColor={price.color}
                h={wrapperHeight + wrapperPaddingX * 2}
                w={wrapperPaddingX}
              />
              <Box
                position="absolute"
                top={wrapperPaddingTop - wrapperPaddingX}
                right="0"
                borderRadius={10 + "px"}
                bgColor={price.color}
                h={wrapperHeight + wrapperPaddingX * 2}
                w={wrapperPaddingX}
              />
              <VStack
                ml={wrapperPaddingX}
                w={mainBlockWidth}
                borderRadius="20px"
                fontFamily="Exo-Bold"
                bgColor="whitesmoke"
                gap={0}
                paddingTop={2}
                paddingBottom={6}
              >
                <Text
                  fontSize="60px"
                  color={price.color}
                >
                  {price.price}
                </Text>
                <Text
                  fontSize="20px"
                  color="black"
                >
                  {price.label}
                </Text>
                <Box h="150px"/>
                {
                  price.features.map((feature, index) => (
                    <Text
                      pt={1}
                      fontSize="20px"
                      color="black"
                      key={index}
                    >
                      {feature}
                    </Text>
                  ))
                }
              </VStack>
              <VStack
                position="absolute"
                top={wrapperPaddingTop}
                h={wrapperHeight}
                w="full"
                bgColor={price.color}
              >
                <Text
                  fontFamily="Arial"
                  fontSize="60px"
                  color="white"
                >
                  {price.words}
                </Text>
                <Text
                  fontFamily="Exo-Regular"
                  fontSize="20px"
                  color="white"
                >
                  {price.period}
                </Text>
              </VStack>
              <Box
                width={wrapperPaddingX}
                height={wrapperPaddingX}
                bgColor={price.shadedColor}
                borderRightRadius={wrapperPaddingX}
                position="absolute"
                top={wrapperPaddingTop - wrapperPaddingX}
                right="0"
              />
              <Box
                width={wrapperPaddingX}
                height={wrapperPaddingX}
                bgColor={price.shadedColor}
                borderLeftRadius={wrapperPaddingX}
                position="absolute"
                top={wrapperPaddingTop - wrapperPaddingX}
                left="0"
              />
              <Box
                width={wrapperPaddingX}
                height={wrapperPaddingX}
                bgColor={price.shadedColor}
                borderRightRadius={wrapperPaddingX}
                position="absolute"
                top={wrapperPaddingTop + wrapperHeight}
                right="0"
              />
              <Box
                width={wrapperPaddingX}
                height={wrapperPaddingX}
                bgColor={price.shadedColor}
                borderLeftRadius={wrapperPaddingX}
                position="absolute"
                top={wrapperPaddingTop + wrapperHeight}
                left="0"
              />
            </Box>
          ))}
        </HStack>
        <DownloadButton/>
        <Image src={airplane} w="100px" py={8}/>
      </VStack>
    </Box>
  );
}