import {AspectRatio, Box, Grid, Text, VStack} from "@chakra-ui/react";
import {DownloadButton} from "./DownloadButton";

type VideoData = {
  url: string,
  label: string
}

const videos = [
  {
    url: "https://www.youtube.com/embed/SAhRHhWUS9k?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0",
    label: "Enhanced Discovery Flights"
  },
  {
    url: "https://www.youtube.com/embed/xW4LvLb15x4?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0",
    label: "Weather Check"
  },
  {
    url: "https://www.youtube.com/embed/-nXbOe1HlNs?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0",
    label: "Aviation Trivia"
  },
  {
    url: "https://www.youtube.com/embed/7vri-PfHASk?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0",
    label: "Radio Frequencies"
  },
  {
    url: "https://www.youtube.com/embed/aFvRUe3kr-s?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0",
    label: "Points Of Interests Guide"
  },
  {
    url: "https://www.youtube.com/embed/eRZLQSDfjs4?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0",
    label: "Checklists Assistance"
  }
]



export const Showcases = () => {
  return (
    <Box
      bgGradient="linear(to-b, #ffffff, #eaf8fd)"
      w="full"
    >
      <VStack
        maxW="940px"
        ml="auto"
        mr="auto"
        pt="50px"
        px={{base: 5, lg: 0}}
      >
        <VStack w="full" alignItems="start">
          <AspectRatio ratio={16 / 9} w="100%">
            <iframe
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FAmxWtu4hgCs%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DAmxWtu4hgCs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FAmxWtu4hgCs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              scrolling="no"
              allowFullScreen={false}
              title="AviMate | AI voice assistant for X-Plane & MSFS | trailer"
            />
          </AspectRatio>
          <Text
            mt={2}
            fontSize="xl"
            fontFamily="Exo-Bold"
            color="black"
          >
            Quick Overview
          </Text>
        </VStack>
        <Grid w="full" templateColumns={{base: 'repeat(1, 1fr)',sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)'}} gap={5} mt={10}>
          {videos.map((video: VideoData, index: number) => (
            <Box key={index} w="full">
              <AspectRatio ratio={16 / 9} w="100%">
                <iframe
                  src={`https://cdn.embedly.com/widgets/media.html?src=${video.url}&url=${video.url}&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube`}
                  scrolling="no"
                  allowFullScreen={false}
                  title={video.label}
                />
              </AspectRatio>
              <Text
                mt={2}
                fontSize="xl"
                fontFamily="Exo-Bold"
                color="black"
              >
                {video.label}
              </Text>
            </Box>
          ))}
        </Grid>
        <DownloadButton/>
      </VStack>
    </Box>
  )
}